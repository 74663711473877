import React, { useEffect, useState } from 'react';
import { Button, Container, CardContent, FormControl, Grid, TextField, FormControlLabel, Typography, Box, Card, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { createTheme } from '@mui/material/styles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Info from './ERC20_comp/Info';
import InfoMobile from './ERC20_comp/InfoMobile';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import QR from '../../static/images/Q.png';
import ChainSelector from '../ChainToogle/ChainSelector';
import { GasTracker } from '../GasTracker/GasTracker';
import { GasDollarEst } from '../GasTracker/GasDollarEst';
import PaymentMethods from '../PaymentMethods/PayeeMethods';

interface IPriceExtra {
    name: string;
    price: string;
}

const priceForExta: IPriceExtra[] = [
    { name: 'Burnable', price: '$45.00' },
    { name: 'Mintable', price: '$35.00' },
    { name: 'Pausable', price: '$95.00' },
    { name: 'Upgradable', price: '$275.00' },
    { name: 'Taxable', price: '$35.00' },
]

function getTotalAmount(extra: any) {
    console.log("ex", extra)
    const selected = Object.keys(extra).filter((key) => extra[key] === true);
    const selectedName = selected.map((item) => item);
    const arrayForNameAndPrice = selectedName.map((item) => {
        return {
            name: item,
            price: priceForExta.find((price) => price.name === item)?.price ?? ''
        }
    })

    const convertSelectedPricesToNumb = arrayForNameAndPrice.map((item) => {
        return {
            name: item.name,
            price: parseInt(item.price.replace('$', ''))
        }
    })

    let getTotalofTotalSelected = 400;

    convertSelectedPricesToNumb.forEach((item) => {
        getTotalofTotalSelected += item.price
    })

    return getTotalofTotalSelected
}

function Review({ extra }: { extra?: any; }) {

    const selected = Object.keys(extra).filter((key) => extra[key] === true);
    const selectedName = selected.map((item) => item);
    const arrayForNameAndPrice = selectedName.map((item) => {
        return {
            name: item,
            price: priceForExta.find((price) => price.name === item)?.price ?? ''
        }
    })

    const convertSelectedPricesToNumb = arrayForNameAndPrice.map((item) => {
        return {
            name: item.name,
            price: parseInt(item.price.replace('$', ''))
        }
    })

    const selectedPricesSum = convertSelectedPricesToNumb.find((item) => item.price)?.price ?? 400

    let getTotalofTotalSelected = 400;

    convertSelectedPricesToNumb.forEach((item) => {
        getTotalofTotalSelected += item.price
    })

    return (
        <Stack spacing={2}>
            <List disablePadding>
                <ListItem sx={{ py: 1, px: 0 }}>
                    <ListItemText primary="Product" secondary="ERC20 Token" />
                    <Typography variant="body2">$400.00</Typography>
                </ListItem>
                {arrayForNameAndPrice.map((item, index) => (
                    <ListItem key={index} sx={{ py: 1, px: 0 }}>
                        <ListItemText primary={item.name} />
                        <Typography variant="body2">{item.price}</Typography>
                    </ListItem>
                ))}
                <ListItem sx={{ py: 1, px: 0 }}>
                    <ListItemText primary="Total" />
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                        ${getTotalofTotalSelected}
                    </Typography>
                </ListItem>
            </List>
            <Divider />
            <Stack
                direction="column"
                divider={<Divider flexItem />}
                spacing={2}
                sx={{ my: 2 }}
            >
                <div>
                    <Typography variant="subtitle2" gutterBottom>
                        Ownership details
                    </Typography>
                    <Typography gutterBottom>Connect Your Wallet</Typography>
                    <Typography color="text.secondary" gutterBottom>
                        {'Please connect your wallet to use our service'}
                    </Typography>
                </div>
            </Stack>
        </Stack>
    );
}

function getStepContent(step: number, extraArray?: any) {
    switch (step) {
        case 0:
            return <Review extra={extraArray} />;
        default:
            throw new Error('Unknown step');
    }
}

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

function BpCheckbox(props: CheckboxProps) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

const ERC20: React.FC = () => {
    const [contractCode, setContractCode] = useState('');
    const [tokenName, setTokenName] = useState('PEPEAISONIC');
    const [tokenSymbol, setTokenSymbol] = useState('PEPEAI');
    const [initialSupply, setInitialSupply] = useState('100000');
    const [checkboxStates, setCheckboxStates] = useState({
        Burnable: false,
        Mintable: false,
        Pausable: false,
        Upgradable: false,
        Taxable: false,
    });
    const [extraArray, setExtraArray] = useState({})
    const [activeStep, setActiveStep] = React.useState(0);
    const [amountToPay, setAmountToPay] = React.useState(400)

    const steps = ['Review'];

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxStates({
            ...checkboxStates,
            [event.target.name]: event.target.checked,
        });

        setExtraArray({
            ...extraArray,
            [event.target.name]: event.target.checked,
        })
    };

    const handleSubmit = () => {
        // Handle form submission here
        console.log('Contract Code:', contractCode);
        console.log('Token Name:', tokenName);
        console.log('Token Symbol:', tokenSymbol);
        console.log('Initial Supply:', initialSupply);
        console.log('Checkbox States:', checkboxStates);
    };

    useEffect(() => {
        function AmountUpdate() {
            setAmountToPay(getTotalAmount(extraArray))
        }

        if (extraArray !== undefined) {
            AmountUpdate()
        }
    }, [extraArray])

    return (
        <Container sx={{ mt: 15 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Divider sx={(theme) => ({
                        mb: 3,
                    })}>
                        <Typography variant="h5"
                            sx={(theme) => ({
                                textShadow:
                                    theme.palette.mode === 'light'
                                        ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                                        : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                            })}>ERC20 TOKEN CREATION</Typography>
                    </Divider>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 5, marginTop: 5 }}>
                        <ChainSelector />
                        <GasTracker gasPrice={36} />
                        <GasDollarEst gasPrice={36} />
                    </div>
                    <Divider sx={{ m: 5 }} />
                </Grid>
                {activeStep === 1 ? (
                    <Grid
                        item
                        sm={12}
                        md={6}
                        lg={6}

                        sx={(theme) => ({
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '100%',
                            width: '100%',
                            backgroundColor: { xs: 'transparent', sm: 'background.default' },
                            alignItems: 'start',
                            pt: { xs: 2, sm: 4, md: 0 },
                            px: { xs: 2, sm: 10 },
                            gap: { xs: 4, md: 8 },
                            border: `1px solid lightgrey`,
                            borderRadius: '5%',
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                                    : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                        })}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: { sm: 'space-between', md: 'flex-end' },
                                alignItems: 'center',
                                width: '100%',
                                maxWidth: { sm: '100%', md: 600 },
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end',
                                    flexGrow: 1,
                                    height: 'auto',
                                    mt: 0
                                }}
                            >
                                <Stepper
                                    id="desktop-stepper"
                                    activeStep={activeStep}
                                    sx={{
                                        width: '100%',
                                        height: 40,
                                    }}
                                >
                                    {steps.map((label) => (
                                        <Step
                                            sx={{
                                                ':first-child': { pl: 0 },
                                                ':last-child': { pr: 0 },
                                            }}
                                            key={label}
                                        >
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Box>
                        <FormControl fullWidth sx={{
                            gap: 4,
                            mt: 5,
                            width: '95%'
                        }}>
                            <TextField
                                required
                                label="Chain"
                                id="filled-size-small"
                                value={tokenName}
                                disabled
                                fullWidth
                            />

                            <TextField
                                label="Token Symbol"
                                required
                                value={tokenSymbol}
                                disabled
                                fullWidth
                            />

                            <TextField
                                label="Initial Supply"
                                type="number"
                                required
                                value={initialSupply}
                                disabled
                                fullWidth
                            />

                        </FormControl>
                        <FormControl
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 0,
                                mt: 5,
                                width: '100%'
                            }}>
                            {checkboxStates.Burnable === true && (<FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={true}
                                        disabled
                                        name="Burnable"
                                    />
                                }
                                label="Burnable"
                            />)}
                            {checkboxStates.Mintable === true && (<FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={true}
                                        disabled
                                        name="Mintable"

                                    />
                                }
                                label="Mintable"
                            />)}
                            {checkboxStates.Pausable === true && (<FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={true}
                                        disabled
                                        name="Pausable"

                                    />
                                }
                                label="Pausable"
                            />)}
                            {checkboxStates.Upgradable === true && (<FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={true}
                                        name="Upgradable"
                                        disabled
                                    />
                                }
                                label="Upgradable"
                            />)}
                            {checkboxStates.Taxable === true && (<FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={true}
                                        disabled
                                        name="Taxable"

                                    />
                                }
                                label="Taxable"
                            />)}
                        </FormControl>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={6}>
                        <Divider sx={{
                            mr: 10,
                            width: '95%'
                        }}>
                            <Typography textAlign="left" variant="h5">CONTRACT DATA</Typography>
                        </Divider>

                        <FormControl fullWidth sx={{
                            gap: 4,
                            mt: 5,
                            width: '95%'
                        }}>
                            <TextField
                                required
                                label="Size"
                                id="filled-size-small"
                                value={tokenName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setTokenName(event.target.value);
                                }}
                                helperText="Enter the name of your ERC20 token. This is the full name that will represent your token."
                                fullWidth
                            />

                            <TextField
                                label="Token Symbol"
                                required
                                helperText="Enter the symbol for your ERC20 token. This is usually a short, uppercase identifier."
                                value={tokenSymbol}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setTokenSymbol(event.target.value);
                                }}

                                fullWidth
                            />

                            <TextField
                                label="Initial Supply"
                                type="number"
                                required
                                helperText="Specify the initial supply of tokens to be minted upon creation."
                                value={initialSupply}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setInitialSupply(event.target.value);
                                }}

                                fullWidth
                            />

                        </FormControl>
                        <Divider sx={{
                            mr: 10,
                            width: '95%',
                            mt: 5
                        }}>
                            <Typography variant="h5">EXTRA</Typography>
                        </Divider>
                        <FormControl
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                mt: 5,
                                mr: 5,
                                width: '300px'
                            }}>
                            <FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={checkboxStates.Burnable}
                                        onChange={handleCheckboxChange}
                                        name="Burnable"

                                    />
                                }
                                label="Burnable"
                            />
                            <FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={checkboxStates.Mintable}
                                        onChange={handleCheckboxChange}
                                        name="Mintable"

                                    />
                                }
                                label="Mintable"
                            />
                            <FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={checkboxStates.Pausable}
                                        onChange={handleCheckboxChange}
                                        name="Pausable"

                                    />
                                }
                                label="Pausable"
                            />
                            <FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={checkboxStates.Upgradable}
                                        onChange={handleCheckboxChange}
                                        name="Upgradable"

                                    />
                                }
                                label="Upgradable"
                            />
                            <FormControlLabel
                                control={
                                    <BpCheckbox
                                        checked={checkboxStates.Taxable}
                                        onChange={handleCheckboxChange}
                                        name="Taxable"

                                    />
                                }
                                label="Taxable"
                            />
                        </FormControl>

                    </Grid>
                )}
                <Grid
                    item
                    sm={12}
                    md={6}
                    lg={6}

                    sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '100%',
                        width: '100%',
                        alignItems: 'start',
                        pt: { xs: 2, sm: 4, md: 0 },
                        px: { xs: 2, sm: 10 },
                        gap: { xs: 4, md: 8 },
                        border: activeStep === 0 ? `1px solid lightgrey` : 'none',
                        borderRadius: '5%',
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                                : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                        bgcolor:
                            theme.palette.mode === 'light'
                                ? 'transparent'
                                : 'rgba(0, 0, 0, 0.4)',
                        backdropFilter: 'blur(24px)',
                    })}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: { sm: 'space-between', md: 'flex-end' },
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: { sm: '100%', md: 600 },
                        }}
                    >
                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end',
                                flexGrow: 1,
                                height: 'auto',
                                mt: 0
                            }}
                        >
                            <Stepper
                                id="desktop-stepper"
                                activeStep={activeStep}
                                sx={{
                                    width: '100%',
                                    height: 40,
                                }}
                            >
                                {steps.map((label) => (
                                    <Step
                                        sx={{
                                            ':first-child': { pl: 0 },
                                            ':last-child': { pr: 0 },
                                        }}
                                        key={label}
                                    >
                                        <StepLabel>{activeStep === 1 ? 'Deposit' : 'Review'}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Box>
                    <Card
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            width: '100%',
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                ':last-child': { pb: 2 },
                            }}
                        >
                            <div>
                                <Typography variant="subtitle2" gutterBottom>
                                    Selected products
                                </Typography>
                                <Typography variant="body1">
                                    {'400$'}
                                </Typography>
                            </div>
                            <InfoMobile totalPrice={activeStep >= 2 ? '$144.97' : '$134.98'} />
                        </CardContent>
                    </Card>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            width: '100%',
                            maxWidth: { sm: '100%', md: 600 },
                            maxHeight: '720px',
                            gap: { xs: 5, md: 'none' },
                            mt: { md: 0 }
                        }}
                    >
                        <Stepper
                            id="mobile-stepper"
                            activeStep={activeStep}
                            alternativeLabel
                            sx={{ display: { sm: 'flex', md: 'none' } }}
                        >
                            {steps.map((label) => (
                                <Step
                                    sx={{
                                        ':first-child': { pl: 0 },
                                        ':last-child': { pr: 0 },
                                        '& .MuiStepConnector-root': { top: { xs: 6, sm: 12 } },
                                    }}
                                    key={label}
                                >
                                    <StepLabel
                                        sx={{ '.MuiStepLabel-labelContainer': { maxWidth: '70px' } }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <Stack spacing={2} useFlexGap sx={{
                                alignItems: 'center',
                                mt: { md: 0 },
                                mb: { md: 10, xs: 10 }
                            }}>
                                <Typography variant="h5">You pay ${amountToPay}</Typography>
                                <img src={QR} alt="" style={{ width: 150, height: 'auto' }} />
                                <Typography variant="h5">Please choose the payment method!</Typography>
                                <div style={{ width: '100%' }}>
                                    <PaymentMethods />
                                </div>
                                <Typography variant="body1" color="text.secondary">
                                    Please select a chain and a token then use VERIFY to start payment verification.
                                </Typography>
                                <Alert color="warning" title="IMPORTANT">
                                    Send the selected token on the selected chain otherwise funds will be lost
                                </Alert>
                                <Button
                                    variant="contained"
                                    sx={{
                                        alignSelf: 'center',
                                        width: { xs: '100%', sm: 'auto' },
                                    }}
                                    disabled
                                >
                                    Token Admin Panel
                                </Button>
                                <Button
                                    startIcon={<ChevronLeftRoundedIcon />}
                                    onClick={handleBack}
                                    variant="text"
                                    sx={{
                                        display: { xs: 'none', sm: 'flex' },
                                    }}
                                >
                                    Back
                                </Button>
                            </Stack>
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep, extraArray)}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column-reverse', sm: 'row' },
                                        justifyContent: activeStep !== 0 ? 'space-between' : 'flex-end',
                                        alignItems: 'end',
                                        flexGrow: 1,
                                        gap: 1,
                                        pb: { xs: 12, sm: 0 },
                                        mt: { xs: 2, sm: 0 },
                                        mb: '60px',
                                    }}
                                >
                                    {activeStep !== 0 && (
                                        <Button
                                            startIcon={<ChevronLeftRoundedIcon />}
                                            onClick={handleBack}
                                            variant="text"
                                            sx={{
                                                display: { xs: 'none', sm: 'flex' },
                                            }}
                                        >
                                            Previous
                                        </Button>
                                    )}
                                    {activeStep !== 0 && (
                                        <Button
                                            startIcon={<ChevronLeftRoundedIcon />}
                                            onClick={handleBack}
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                                display: { xs: 'flex', sm: 'none' },
                                            }}
                                        >
                                            Previous
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        endIcon={<ChevronRightRoundedIcon />}
                                        onClick={handleNext}
                                        sx={(theme) => ({
                                            width: { xs: '100%', sm: 'fit-content' },
                                            boxShadow:
                                                theme.palette.mode === 'light'
                                                    ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                                                    : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                                        })}
                                    >
                                        {activeStep === steps.length - 1 ? 'Create Token' : 'Next'}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                </Grid>
                <Divider sx={{ m: 5 }} />
            </Grid>
        </Container >
    );
};

export default ERC20;
