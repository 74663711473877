import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Box, Button } from '@mui/material';

const PaymentMethods = () => {
    const [selectedPayment, setSelectedPayment] = useState('');

    const handlePaymentChange = (event: SelectChangeEvent<string>) => {
        setSelectedPayment(event.target.value as string);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 5
        }}>
            <FormControl sx={{
                minWidth: {md: 150}
            }}>
                <InputLabel id="payment-label">Chain</InputLabel>
                <Select
                    label="payment-label"
                    id="payment-select"
                    value={selectedPayment}
                    onChange={(e) => handlePaymentChange(e)}
                >
                    <MenuItem value="Bitcoin">Bitcoin</MenuItem>
                    <MenuItem value="Ethereum">Ethereum</MenuItem>
                    <MenuItem value="Cronos">Cronos</MenuItem>
                    <MenuItem value="Matic">Matic</MenuItem>
                    <MenuItem value="Binance">Binance</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{
                minWidth: {md: 150}
            }}>
                <InputLabel id="payment-label">Token</InputLabel>
                <Select
                    label="payment-label"
                    id="payment-select"
                    value={selectedPayment}
                    onChange={(e) => handlePaymentChange(e)}
                >
                    <MenuItem value="Bitcoin">BTC</MenuItem>
                    <MenuItem value="Ethereum">USDC</MenuItem>
                    <MenuItem value="Cronos">CRO</MenuItem>
                    <MenuItem value="Matic">MATIC</MenuItem>
                    <MenuItem value="Binance">BNB</MenuItem>
                </Select>
            </FormControl>

            <Button sx={(theme) => ({
                boxShadow: theme.palette.mode === 'light'
                    ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                    : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
            })}
                variant="contained"
                color="primary">VERIFY</Button>
        </Box>
    );
};

export default PaymentMethods;