/* eslint-disable react-hooks/exhaustive-deps */


/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import DatabaseHelper from '../../classes/DatabaseHelper';
import AddressFactory from '../../common/AddressFactory';
import { IEtherContext } from '../../helper/IEtherContext';
import EtherHelper from '../../helper/EtherHelper';
import { Alert, Button, CircularProgress, styled, Typography } from '@mui/material';
import LOGO_P from '../../static/images/logo_pant.png'
import { Modal } from '@mui/material';

const StyledBox = styled('div')(({ theme }) => ({
    width: 400,
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    pt: 2,
    px: 4,
    pb: 3,
    transform: 'translate(-50%, -50%)',
    marginTop: theme.spacing(8),
    borderRadius: theme.shape.borderRadius,
    boxShadow:
        theme.palette.mode === 'light'
            ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
            : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    outline: '1px solid',
    backgroundImage: `url(${theme.palette.mode === 'light'
        ? 'rgba(0,0,0 0.2)'
        : 'rgba(0,0,0 0.2)'
        })`,
    backdropFilter: 'blur(24px)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    outlineColor:
        theme.palette.mode === 'light'
            ? 'hsla(220, 25%, 80%, 0.5)'
            : 'hsla(210, 100%, 80%, 0.1)',
    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(10),
        height: 700,
        width: 700
    },
}));

interface P_SignUp {
    username: string;
    email: string;
    password: string;
    isWallet: boolean;
    address?: string;
}

interface P_SignIn {
    email: string;
    password: string;
    isWallet: boolean;
    address?: string;
}


async function verifyIfUserExists(db_helper: DatabaseHelper, context: IEtherContext) {
    // Check if user exists
    let userExist = false;
    try {
        //@dev - check user by address
        const user = await db_helper.getUserByAddress(context.addressSigner ?? '')
        if (user) {
            // User exists
            userExist = true;
            return userExist;
        } else {
            // User does not exist
            userExist = false;
            return userExist;
        }
    } catch (e: any) {
        console.log(e)
    }
}

/**
 * @name DBVerifier
 * @param handleOpen 
 * @param handleClose 
 * @param context 
 * @param signIn 
 * @param signUp 
 * @param saveContext 
 * @returns 
 */
export const SPVerifier = ({
    open,
    handleClose,
    context,
    signUp,
    saveContext
}: {
    open: boolean;
    handleClose: () => void;
    context: IEtherContext;
    signUp: P_SignUp | null;
    saveContext?: (ctx: IEtherContext) => void;
}) => {
    const db_helper = new DatabaseHelper(AddressFactory.getDBEndpoint(true));
    const [userExist, setUserExist] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('error');
    const [success, setSuccess] = React.useState<string>('success');

    async function _SignUp(signUp: P_SignUp) {
        try {
            const user = await db_helper.signup(signUp);
            alert(`Welcome, ${user.username}`);
            saveContext && saveContext({ ...context, username: user.username, email: user.email, addressSigner: user.address, password: user.password});
            setIsLoading(false)
            console.log(context)
        } catch (error: any) {
            alert(error.response.data.message);
            setIsLoading(false)
            console.log(context)
        }
    }

    useEffect(() => {
        if (signUp !== null) {
            setIsLoading(true)
            _SignUp(signUp)
        }
    }, [signUp])

    return (
        <Modal open={open} onClose={handleClose}>
            <StyledBox>
                {isLoading && (
                    <div>
                        <CircularProgress /> {/* Add the spinner component from @mui/material */}
                    </div>
                )}
                {!isLoading && !error && success === "success" && (
                    <Alert severity="success">Connected!</Alert>
                )}
                {!isLoading && !success && error === "error" && (
                    <Alert severity="error">Not Connected! Something went wrong...</Alert>
                )}
                {userExist && !isLoading && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography variant="h5">Welcome {context.username ?? context.addressSigner}!</Typography>
                        <Typography variant="body1">You will be redirected to your dashboard!</Typography>
                    </div>
                )}
                {!userExist && !isLoading && signUp === null && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        top: '25%',
                        gap: 2
                    }}>
                        <img alt="" src={LOGO_P} style={{ width: 200, height: 'auto' }} />
                        <Typography variant="body1">Sorry, something went wrong!</Typography>
                    </div>

                )}
            </StyledBox>
        </Modal>
    )
}
