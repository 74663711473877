import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Ethereum from '../../static/assets/Ethereum';
import Matic from '../../static/assets/Matic';
import CronosIcon from '../../static/assets/Cronos';
import Bnb from '../../static/assets/Bnb';

const Cronos = () => {
    return (
        <div>
            <CronosIcon />
        </div>
    );
};

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
        margin: theme.spacing(1),
    },
    
}));


const ChainToggle: React.FC = () => {

  const [chain, setChain] = React.useState('');

  const handleChainChange = (event: React.MouseEvent<HTMLElement>, newChain: string | null) => {
    if (newChain !== null) {
      setChain(newChain);
    }
  };

  return (
    <Container>
      <ToggleButtonGroup value={chain} exclusive onChange={handleChainChange}>
        <ToggleButton value="chain1">
          <Ethereum /> Ethereum
        </ToggleButton>
        <ToggleButton value="chain2">
          <Matic /> Polygon
        </ToggleButton>
        <ToggleButton value="chain3">
           <Cronos/> Cronos
        </ToggleButton>
        <ToggleButton value="chain4">
          <Bnb /> Binance 
        </ToggleButton>
      </ToggleButtonGroup>
    </Container>
  );
};

export default ChainToggle;
