import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';

const ChainSelector: React.FC = () => {
    const [selectedChain, setSelectedChain] = useState('');

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedChain(event.target.value as string);
    };

    return (
        <FormControl sx={{minWidth: 200, maxWidth: 400}}>
            <InputLabel id="chain-selector-label">Chain</InputLabel>
            <Select
                labelId="chain-selector-label"
                label="Chain"
                required
                value={selectedChain}
                onChange={(event) => handleChange(event)}
            >
                <MenuItem value="Polygon">Polygon</MenuItem>
                <MenuItem value="Cronos">Cronos</MenuItem>
                <MenuItem value="Base">Base</MenuItem>
                <MenuItem value="Ethereum">Ethereum</MenuItem>
                <MenuItem value="Binance Chain">Binance Chain</MenuItem>
            </Select>
        </FormControl>
    );
};

export default ChainSelector;