import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import AppAppBar from './components/AppAppBar';
import { Box, createTheme, CssBaseline, Divider, IconButton, List, PaletteMode, ThemeProvider, Toolbar, useMediaQuery, Theme, styled, Alert } from '@mui/material';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import getLPTheme from './getLPTheme';
import Features from './components/Features';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollections';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './components/SignIn/SignIn';
import { ThemeHelper } from './classes/ThemeHelper';
import SignUp from './components/SignUp/SignUp';
import Dashboard from './components/Dashboard/Dashboard';
import { Analytics } from './components/Dashboard/Analytics/Analytics';
import ERC20 from './components/Tools/ERC20';
import { ChevronLeftOutlined, ChevronLeftRounded, Menu as MenuIcon } from '@mui/icons-material';
import { mainDashList, secondaryDashItems } from './components/dash_list/dashList';
import MuiDrawer from '@mui/material/Drawer';
import DatabaseHelper from './classes/DatabaseHelper';
import AddressFactory from './common/AddressFactory';
import { EtherContextRepository } from './helper/EtherContextRepository';
import { EtherContext } from './helper/EtherContext';
import QuoteRequestForm from './components/Forms/FormQuote';
import { darkTheme, lightTheme } from './theme/Theme';

function WrapperApp() {
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Hero />
      <Features />
      <Highlights />
      <Pricing />
      <LogoCollection />
      <Footer />
    </Box>
  );
}

function App() {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(true);
  const dataHelper = new DatabaseHelper(AddressFactory.getDBEndpoint(true));
  const { context, saveContext } = React.useContext(EtherContext) as EtherContextRepository

  const toggleColorMode = () => {
    setMode(prev => (prev === 'dark' ? 'light' : 'dark'));
    ThemeHelper.setTheme(mode);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const userData = await dataHelper.getMe(token);
          setUser(userData);
          console.log(userData);
        } catch (error) {
          //localStorage.removeItem('token');
          console.log(error);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log('context', context);
    console.log(localStorage.getItem('token'));
  }, []);

  function closeAlert() {
    setAlert(false);
  }

  return (
    <ThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
      <CssBaseline />
      {/*pathName !== '/dashboard' && <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />*/}
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} user={user} />
      <Box sx={{ display: 'flex' }}>
        <Router>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Alert sx={{mt: 10, mb: -3, ml: 3, mr: 3}} severity="info">The site is still under development. We are currently adding the user section and all functionalities before the launch. Please make sure you are visiting https://ethercode.dev/</Alert>
            <Routes>
              <Route path="/signIn" element={<SignIn />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/erc20" element={<ERC20 />} />
              <Route path="/form_quote" element={<QuoteRequestForm />} />
              <Route path="/dashboard" element={<Dashboard open={open} toggleDrawer={toggleDrawer} />} />
              <Route path="/" element={<WrapperApp />} />
            </Routes>
          </Box>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
