import React from 'react';
import { Paper, Typography, Button, makeStyles, styled } from '@mui/material';
import darkbanner_promo from '../../../static/images/dp.png';
import lightbanner_promo from '../../../static/images/lp.png';
import dp from "../../../static/images/dp.png"
import lp from "../../../static/images/lp.png"

const StyledPaperBanner = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    height: 170,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    borderRadius: theme.shape.borderRadius,
    boxShadow:
        theme.palette.mode === 'light'
            ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
            : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    outline: '1px solid',
    backgroundImage: `url(${theme.palette.mode === 'light'
        ? lp
        : dp
        })`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
        theme.palette.mode === 'light'
            ? 'white'
            : 'black',
    objectFit: 'cover',
    outlineColor:
        theme.palette.mode === 'light'
            ? 'hsla(220, 25%, 80%, 0.5)'
            : 'hsla(210, 100%, 80%, 0.1)',
    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        height: 170,
    },
}));


const PromoBanner: React.FC = () => {
    return (
        <StyledPaperBanner>
            <Button sx={(theme) => ({
                boxShadow:
                    theme.palette.mode === 'light'
                        ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                        : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                outline: '1px solid',
                backgroundColor:
                    theme.palette.mode === 'light'
                        ? 'hsla(220, 25%, 80%, 0.5)'
                        : 'hsla(210, 100%, 80%, 0.1)',
                height: 30,
                color:
                    theme.palette.mode === 'light'
                        ? 'black'
                        : 'white',
                mt: theme.spacing(6),
                mr: theme.spacing(1)
            })} variant="contained" color="primary">
                Shop Now
            </Button>
        </StyledPaperBanner>
    );
};

export default PromoBanner;