import React from 'react';
import { Typography, Box, IconButton, styled } from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
const RBox = styled('div')(({ theme }) => ({
    display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.shape.borderRadius,
}))

interface GasTrackerProps {
    gasPrice: number;
}

export const GasDollarEst: React.FC<GasTrackerProps> = ({ gasPrice }) => {

    const estimationPrice = gasPrice * 1.14

    return (
        <RBox>
            <CurrencyExchangeIcon style={{marginRight: 2}} /> {/* Sostituisci con l'icona desiderata */}
            <Typography variant="body1">
                ERC20 fees: ~ ${estimationPrice.toLocaleString("en-US", {
                    maximumFractionDigits: 2
                })}
            </Typography>
            <IconButton>
                {/* Aggiungi qui l'azione desiderata per il pulsante */}
            </IconButton>
        </RBox>
    );
};