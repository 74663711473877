import React from 'react';

interface PairImageHelperProps {
    native: string;
    token: string;
    isImageUrl: boolean
}

export class PairImageHelper {

    private static imageListePerName: { [key: string]: string } = {
        "WCRO": "https://cryptologos.cc/logos/cronos-cro-logo.png?v=032",
        "WETH": "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=032",
        "CRO": "https://cryptologos.cc/logos/cronos-cro-logo.png?v=032",
        "ETH": "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=032",
        "WBTC": "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=032",
        "BTC": "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=032",
        "WBNB": "https://cryptologos.cc/logos/binance-coin-bnb-logo.png?v=032",
        "BNB": "https://cryptologos.cc/logos/binance-coin-bnb-logo.png?v=032",
        "WAVAX": "https://cryptologos.cc/logos/avalanche-avax-logo.png?v=032",
        "AVAX": "https://cryptologos.cc/logos/avalanche-avax-logo.png?v=032",
        "USDC": "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=032",
        "USDT": "https://cryptologos.cc/logos/tether-usdt-logo.png?v=032",
        "DAI": "https://cryptologos.cc/logos/dai-dai-logo.png?v=032",
        "WBUSD": "https://cryptologos.cc/logos/binance-usd-busd-logo.png?v=032",
        "BUSD": "https://cryptologos.cc/logos/binance-usd-busd-logo.png?v=032",        
    }
    
    public static renderPairedImages(props: PairImageHelperProps): React.ReactElement {
        const { native, token, isImageUrl } = props;

        let NativeImage = this.imageListePerName[native];
        let TokenImage = this.imageListePerName[token];

        if (isImageUrl === true) {
            return (
                <div style={{ maxWidth: 50 }}>
                    <div style={{ display: 'flex' }}>
                        <img src={native} alt="" style={{ width: '50%', objectFit: 'cover' }}  />
                        <img src={TokenImage} alt="" style={{ width: '50%', objectFit: 'cover' }} />
                    </div>
                </div>
            );
        }

        return (
            <div style={{ maxWidth: 50 }}>
                <div style={{ display: 'flex' }}>
                    <img src={NativeImage} alt="" style={{ width: '50%', objectFit: 'cover' }}  />
                    <img src={TokenImage} alt="" style={{ width: '50%', objectFit: 'cover' }} />
                </div>
            </div>
        );
    }
}