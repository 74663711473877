import React, { useEffect } from "react";
import { Avatar, Box, Button, Container, createTheme, CssBaseline, Dialog, Divider, Grid, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemText, Modal, PaletteMode, Paper, Select, Toolbar, Typography } from "@mui/material";
import { MenuBookOutlined, SearchOffOutlined, Directions, Filter1Outlined, SettingsAccessibilityRounded, FilterList, SendRounded, Folder, Delete } from "@mui/icons-material";
import { ThemeHelper } from "../../../classes/ThemeHelper";
import { verifier } from "./verifier";
import { QueryHelper } from "../../../helper/DexScreener/QueryHelper";
import MultiPair from "./MultiPair";
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { TokensCollections } from "./TokensCollections";

interface InputBarTokensProps {
    setInputValue: any;
    setInv_token: any;
    getTokenData: (value: string) => void;
    setOpenModal: (value: boolean) => void;
}

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

// Inspired by blueprintjs
function BpCheckbox(props: CheckboxProps) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

const InputBarTokens: React.FC<InputBarTokensProps> = ({ setInputValue, setInv_token, getTokenData, setOpenModal }) => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent default form submission behavior
        const value = (e.currentTarget.querySelector('input') as HTMLInputElement).value;
        getTokenData(value);
    };

    return (
        <Paper
            component="form"
            onSubmit={handleSubmit}
            sx={(theme) => ({
                p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%',
                borderRadius: theme.shape.borderRadius,
                boxShadow:
                    theme.palette.mode === 'light'
                        ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                        : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                outline: '1px solid',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={() => setOpenModal(true)}>
                <FilterList />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="E.g.: WBTC or WBTC/USDC or 0xAbc01"
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton sx={{ p: '10px' }} aria-label="directions">
                <SendRounded />
            </IconButton>
        </Paper>
    );
}

export const Analytics = () => {
    const [mode, setMode] = React.useState<PaletteMode>('light');
    const defaultTheme = createTheme({ palette: { mode } });
    const [tokenValue, setTokenValue] = React.useState('');
    const [notValid, setNotValid] = React.useState(false);
    const [invToken, setInv_Token] = React.useState(false);
    const [pairOrPairs, setPairOrPairs] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);

    const toggleColorMode = () => {
        setMode((prev: string) => (prev === 'dark' ? 'light' : 'dark'));
        console.log(mode)
        ThemeHelper.setTheme(mode);
    };

    useEffect(() => {
        toggleColorMode();
    }, []);

    const filters_: any[] = [
        {
            title: "Most Relevant",
        },
        {
            title: "Least Relevant",
        },
        {
            title: "Most Profitable 24H",
        },
        {
            title: "Least Profitable 24H"
        },
        {
            title: "Recent",
        },
        {
            title: "Least Recent"
        }
    ]

    function filterItems(input: string) {
        const filters = {
            "Most Relevant": false,
            "Least Relevant": false,
            "Most Profitable 24H": false,
            "Least Profitable 24H": false,
            "Recent": false,
            "Least Recent": false
        };

        if (input === "Most Relevant") {
            filters["Most Relevant"] = true;
        } else if (input === "Least Relevant") {
            filters["Least Relevant"] = true;
        } else if (input === "Most Profitable 24H") {
            filters["Most Profitable 24H"] = true;
        } else if (input === "Least Profitable 24H") {
            filters["Least Profitable 24H"] = true;
        } else if (input === "Recent") {
            filters["Recent"] = true;
        } else if (input === "Least Recent") {
            filters["Least Recent"] = true;
        }

        return filters;
    }

    //const input = "Most Relevant";
    //const filters = filterItems(input);
    //console.log(filters);

    const getTokenData = async (value: string) => {
        console.log(value);
        if (verifier(value)) {
            setTokenValue(value);
        } else {
            setNotValid(true);
        }
    }

    const setInputValue = (value: string) => {
        console.log(value);
        if (verifier(value)) {
            setTokenValue(value);
        } else {
            setNotValid(true);
        }
    }

    useEffect(() => {
        async function _getTokenData() {
            const res = await QueryHelper.fetchDataToken(tokenValue)
            console.log("RES", res)
            if (res) {
                setPairOrPairs(res.pairs)
            }
        }

        if (tokenValue === '') return;
        _getTokenData()
    }, [tokenValue])

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box
                component="main"
                sx={(theme) => ({
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : 'black',
                    width: '100%',
                    backgroundImage:
                        theme.palette.mode === 'light'
                            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
                            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
                    backgroundRepeat: 'no-repeat',
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                })}>
            <Toolbar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Dialog
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ width: 400, height: 400, bgcolor: 'background.paper', p: 4 }}>
                        {filters_.map((filter) => {
                            return (
                                <ListItem
                                    key={filter}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete">
                                            <BpCheckbox />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Folder />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={filter.title}
                                    />
                                </ListItem>
                            )
                        })}
                    </Box>
                    <Button onClick={() => setOpenModal(false)}>Close</Button>
                </Dialog>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <InputBarTokens setInputValue={setInputValue} setInv_token={setInv_Token} getTokenData={getTokenData} setOpenModal={setOpenModal} />
                        </div>
                        {pairOrPairs.length >= 1 && pairOrPairs.map((pair, index) => {
                            return (
                                <MultiPair pair={pair} index={index} />
                            )
                        })}
                        {invToken ? <Typography variant="h6" color="error">Invalid token address</Typography> : <></>}
                    </Grid>
                    {/* Chart */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper
                            sx={{
                                p: 2,
                                height: 'auto',
                            }}
                        >
                            <TokensCollections/>
                        </Paper>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            {/* <NetWorth /> */}
                        </Paper>
                    </Grid>
                    {/* Recent Orders */}
                    <Grid item xs={12}>
                        {/* <ChainToggle /> */}
                        {/* <Orders /> */}
                    </Grid>
                </Grid>
                {/* <Copyright sx={{ pt: 4 }} /> */}
            </Container>
        </Box>
        </Box >
    );
}
