import axios from 'axios';

export interface Pair {
    chainId: string;
    dexId: string;
    url: string;
    info: {
        imageUrl: string;
    };
    pairAddress: string;
    baseToken: {
        address: string;
        name: string;
        symbol: string;
    };
    quoteToken: {
        symbol: string;
    };
    priceNative: string;
    priceUsd?: string;
    txns: {
        m5: {
            buys: number;
            sells: number;
        };
        h1: {
            buys: number;
            sells: number;
        };
        h6: {
            buys: number;
            sells: number;
        };
        h24: {
            buys: number;
            sells: number;
        };
    };
    volume: {
        m5: number;
        h1: number;
        h6: number;
        h24: number;
    };
    priceChange: {
        m5: number;
        h1: number;
        h6: number;
        h24: number;
    };
    liquidity?: {
        usd?: number;
        base: number;
        quote: number;
    };
    fdv?: number;
    pairCreatedAt?: number;
}

export interface SearchResponse {
    schemaVersion: string;
    pairs: Pair[];
}

export class QueryHelper {

    public static async fetchDataToken(data: string): Promise<any> {
        const url = "https://api.dexscreener.io/latest/dex/search?q=";
        const matching = `${url}${data}`;
        try {
            const response = await axios.get(matching);
            return response.data as Pair;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }
}
