import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import divitrendlogo from '../static/images/77.png';
import fmglogo from '../static/images/fmg.png'
import mnob from '../static/images/mnob.png'
import burny from '../static/images/burny.png'

const whiteLogos = [
  `${divitrendlogo}`,
  `${fmglogo}`,
  `${mnob}`,
  `${burny}`,
];

const darkLogos = [
    `${divitrendlogo}`,
    `${fmglogo}`,
    `${mnob}`,
    `${burny}`,
];

const logoStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  opacity: 0.7,
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4, backgroundColor: theme.palette.mode === 'light' ? 'black' : 'black' }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="white"
      >
        Trusted by the best
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.6 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}