import React from 'react';
import { Card, CardContent, Typography, Grid, Chip, Button } from '@mui/material';
import { Pair } from '../../../helper/DexScreener/QueryHelper';
import { PairImageHelper } from '../../PairStatic/PairImageHelper';

interface MultiPairProps {
    pair: Pair;
    index: number;
}

const MultiPair: React.FC<MultiPairProps> = ({ pair, index }) => {
    const handleClick = () => {
        // Logica da eseguire al clic sulla card
    };

    const formatPrice = (price: string | undefined) => {
        if (!price) return '';
        const decimalCount = (price.split('.')[1] || '').length;
        if (decimalCount >= 6) {
            const findAndCountZero = price.split('.')[1].match(/0/g);
            const numberAfterZeros = price.split('.')[1].substring(findAndCountZero ? findAndCountZero.length : 0);
            const integerPart = price.split('.')[0];
            console.log(
                "integerPart: ", integerPart,
                "findAndCountZero: ", findAndCountZero,
                "numberAfterZeros: ", numberAfterZeros
            )
            return (
                    <div style={{display: 'flex', flexDirection: 'row'}}>{`$${0.}.0`}<Typography style={{fontSize: '15px'}}>{findAndCountZero?.length}</Typography>{`${numberAfterZeros}`}</div>
            );
        } else {
            return (
                <div style={{display: 'flex', flexDirection: 'row'}}>${price}</div>
            )
        }
    };

    const PairList: React.ReactElement<any> = PairImageHelper.renderPairedImages({ native: pair.info ? pair.info.imageUrl : pair.baseToken.symbol, token: pair.info ? pair.quoteToken.symbol : pair.quoteToken.symbol, isImageUrl: pair.info ? true : false })

    return (
        <Card 
        sx={(theme) => ({
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
                : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
            backgroundRepeat: 'no-repeat',
            mt: 2, mb: 2 
          })}
         onClick={handleClick}>
            <CardContent>
                <Grid container spacing={1} style={{gap: 10}}> {/* Spazio tra gli item */}
                    <Grid item>
                        {PairList}
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" component="div">
                            {pair.baseToken.symbol}/{pair.quoteToken.symbol}
                        </Typography>
                        <Typography variant="body1" component="div">
                            {pair.baseToken.name}
                        </Typography>
                    </Grid>

                    <Grid item sx={{ ml: 5, alignItems: 'center' }}>
                        <Typography variant="body1" component="div">
                            {pair.dexId.includes('0x') ? 'Unverified' : pair.dexId}
                        </Typography>
                        <Chip sx={{ height: 20, ml: 1 }} label={pair.chainId} color={pair.dexId.includes('0x') ? 'error' : 'success'} />
                    </Grid>

                    <Grid item sx={{ ml: 5 }}>
                        <Typography sx={{ color: pair.priceChange.h24 > 0 ? 'green' : 'red', fontWeight: 'bold' }} variant="h5" component="div">
                            {formatPrice(pair.priceUsd)}
                        </Typography>
                        <Typography sx={{ ml: 1, fontStyle: 'italic', color: pair.priceChange.h24 > 0 ? 'green' : 'red' }} variant="body1" component="div">
                            {pair.priceChange.h24}%
                        </Typography>
                    </Grid>

                    <Grid item sx={{ ml: 5 }}>
                        <Typography sx={{ color: pair.priceChange.m5 > 0 ? 'green' : 'red', }} variant="h6" component="div">
                            5M
                        </Typography>
                        <Typography sx={{ fontStyle: 'italic', color: pair.priceChange.m5 > 0 ? 'green' : 'red' }} variant="body1" component="div">
                            {pair.priceChange.m5}%
                        </Typography>
                    </Grid>

                    <Grid item sx={{ ml: 0 }}>
                        <Typography sx={{ color: pair.priceChange.h1 > 0 ? 'green' : 'red', }} variant="h6" component="div">
                            1H
                        </Typography>
                        <Typography sx={{ fontStyle: 'italic', color: pair.priceChange.h1 > 0 ? 'green' : 'red' }} variant="body1" component="div">
                            {pair.priceChange.h1}%
                        </Typography>
                    </Grid>

                    <Grid item sx={{ ml: 0 }}>
                        <Typography sx={{ color: pair.priceChange.h6 > 0 ? 'green' : 'red', }} variant="h6" component="div">
                            6H
                        </Typography>
                        <Typography sx={{ fontStyle: 'italic', color: pair.priceChange.h6 > 0 ? 'green' : 'red' }} variant="body1" component="div">
                            {pair.priceChange.h6}%
                        </Typography>
                    </Grid>

                    <Grid item sx={{ ml: 0 }}>
                        <Typography sx={{ color: pair.priceChange.h24 > 0 ? 'green' : 'red', }} variant="h6" component="div">
                            24H
                        </Typography>
                        <Typography sx={{ fontStyle: 'italic', color: pair.priceChange.h24 > 0 ? 'green' : 'red' }} variant="body1" component="div">
                            {pair.priceChange.h24}%
                        </Typography>
                    </Grid>


                    <Grid item sx={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2 }}> {/* Posizionato alla fine della card e allineato a destra */}
                        <Button variant="contained" color="success" sx={{ marginBottom: 1 }}>
                            <Typography variant="body1" component="div">
                                Trade
                            </Typography>
                        </Button>

                        <Button variant="contained" sx={{ marginBottom: 1 }}>
                            <Typography variant="body1" component="div">
                                View
                            </Typography>
                        </Button>
                    </Grid>
                    {/* Altri contenuti della card */}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MultiPair;
