import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { PaletteMode, Card as MuiCard } from '@mui/material';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import LOGO from '../../static/images/cropped.png';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import ForgotPassword from './ForgotPassword';
import getLPTheme from '../../getLPTheme';
import ToggleColorMode from '../ToggleColorMode';
import { GoogleIcon, FacebookIcon, SitemarkIcon } from './CustomIcons';
import { WalletOutlined } from '@mui/icons-material';
import { ThemeHelper } from '../../classes/ThemeHelper';
import { EtherContextRepository } from '../../helper/EtherContextRepository';
import { EtherContext } from '../../helper/EtherContext';
import { DBVerifier } from '../_verifierDB/DBVerifier';
import DatabaseHelper from '../../classes/DatabaseHelper';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: 120,
    alignSelf: 'center',
    gap: theme.spacing(4),
    width: '100%',
    minHeight: '700px',
    padding: theme.spacing(2),
    backgroundColor:
        theme.palette.mode === 'light'
            ? 'transparent'
            : 'rgba(0,0,0 0.1)',
    backdropFilter: 'blur(20px)',
    boxShadow:
        theme.palette.mode === 'light'
            ? 'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px, hsla(220, 30%, 5%, 0.05) 0px 0px 0px 1px'
            : 'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px, hsla(220, 30%, 5%, 0.05) 0px 0px 0px 1px',
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
        width: '450px',
    },
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
    height: 'auto',
    padingBottom: theme.spacing(12),
    backgroundColor:
        theme.palette.mode === 'light'
            ? 'transparent'
            : 'rgba(0,0,0 0.3)',
    backdropFilter: 'blur(20px)',
    [theme.breakpoints.up('sm')]: {
        paddingBottom: 0,
        height: '120dvh',
        marginTop: 0
    },
}));

interface P_SignIn {
    email: string;
    password: string;
    isWallet: boolean;
    address?: string;
}

interface P_SignUp {
    username: string;
    email: string;
    password: string;
    isWallet: boolean;
    address?: string;
}

export default function SignIn() {
    const [showCustomTheme, setShowCustomTheme] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const { context, saveContext } = React.useContext(EtherContext) as EtherContextRepository;
    const [signIn, setSignIn] = React.useState<P_SignIn | null>(null);
    //const [signUp, setSignUp] = React.useState<P_SignUp | null>(null);
    const [openForgot, setOpenForgot] = React.useState(false)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenForgot = () => {
        setOpenForgot(true)
    }

    const handleCloseForgot = () => {
        setOpenForgot(false)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (emailErrorMessage || passwordErrorMessage) return  event.preventDefault();
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setSignIn({
            email: data.get('email') as string,
            password: data.get('password') as string,
            isWallet: false
        })
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
        handleOpen();
    };

    const validateInputs = () => {
        const email = document.getElementById('email') as HTMLInputElement;
        const password = document.getElementById('password') as HTMLInputElement;

        let isValid = true;

        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value || password.value.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Password must be at least 6 characters long.');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        return isValid;
    };

    return (

        <SignInContainer direction="column" justifyContent="space-between">
            {context && (
                <DBVerifier
                    open={open}
                    handleClose={handleClose}
                    context={context}
                    signIn={signIn}
                    signUp={null}
                    saveContext={saveContext}
                />
            )}
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    position: { xs: 'static', sm: 'fixed' },
                    width: '100%',
                    p: { xs: 2, sm: 4 },
                }}
            >
                <Button
                    startIcon={<ArrowBackRoundedIcon />}
                    component="a"
                    href="/"
                >
                    Back
                </Button>
            </Stack>
            <Stack
                justifyContent="center"
                sx={{ height: { xs: '100%', sm: '100dvh' }, p: 2 }}
            >
                <Card sx={(theme) => ({
                    boxShadow:
                        theme.palette.mode === 'light'
                            ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                            : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                    bgColor:
                        theme.palette.mode === 'light'
                            ? 'transparent'
                            : 'rgba(0, 0, 0, 0.4)',
                })}>
                    <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                        <img alt="" src={LOGO} style={{ height: 'auto', width: 50 }} />
                    </div>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                    >
                        Sign in
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <TextField
                                error={emailError}
                                helperText={emailErrorMessage}
                                id="email"
                                type="email"
                                name="email"
                                placeholder="your@email.com"
                                autoComplete="email"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={emailError ? 'error' : 'primary'}
                                sx={{ ariaLabel: 'email' }}
                            />
                        </FormControl>
                        <FormControl>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Link
                                    component="button"
                                    onClick={handleOpenForgot}
                                    variant="body2"
                                    sx={{ alignSelf: 'baseline' }}
                                >
                                    Forgot your password?
                                </Link>
                            </Box>
                            <TextField
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                name="password"
                                placeholder="••••••"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={passwordError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <ForgotPassword open={openForgot} handleClose={handleCloseForgot} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={validateInputs}
                        >
                            Sign in
                        </Button>
                        <Link
                            href="/material-ui/getting-started/templates/sign-up/"
                            variant="body2"
                            sx={{ alignSelf: 'center' }}
                        >
                            Don&apos;t have an account? Sign up
                        </Link>
                    </Box>
                    <Divider>or</Divider>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            onClick={() => alert('Sign in with Google')}
                            startIcon={<WalletOutlined />}
                        >
                            CONNECT WALLET
                        </Button>
                        {/*<Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            onClick={() => alert('Sign in with Google')}
                            startIcon={<GoogleIcon />}
                        >
                            Sign in with Google
                        </Button>*/}
                    </Box>
                </Card>
            </Stack>
        </SignInContainer>
    );
}