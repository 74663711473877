import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material'
import PromoBanner from './PromoBanner'

const exampleCardToken = [
    {
        name: 'Divitrend',
        symbol: 'TRND',
        price: '0.00004576',
        value: '100.00',
        change: '+5.00',
        changePercentage24: '+28%',
        changePercentage1m: '+423%',
        changePercentage6m: '+932%',
    },
    {
        name: 'Growty',
        symbol: 'GRO',
        price: '12.34',
        value: '200.00',
        change: '+10.00',
        changePercentage24: '+12.2%',
        changePercentage1m: '+122%',
        changePercentage6m: '+564%',
    },
    {
        name: 'Tsuna',
        symbol: 'TSU',
        price: '167.82',
        value: '300.00',
        change: '+15.00',
        changePercentage24: '+12%',
        changePercentage1m: '+97%',
        changePercentage6m: '+382%',
    },
    {
        name: 'Waltix',
        symbol: 'WALT',
        price: '14.21',
        value: '400.00',
        change: '+4.00',
        changePercentage24: '+4.3%',
        changePercentage1m: '+39%',
        changePercentage6m: '+239%',
    },
    {
        name: 'Cryptra',
        symbol: 'CRP',
        price: '458.33',
        value: '500.00',
        change: '+8.00',
        changePercentage24: '+8%',
        changePercentage1m: '+17%',
        changePercentage6m: '+169%',
    }
]


export const TokensCollections = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography variant="h3" component="h1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                TOP 5 TOKENS
            </Typography>
            <Grid container sx={{ gap: 2, alignItems: 'center', display: 'flex', justifyContent: 'center', mt: 5 }}>

                {exampleCardToken.map((token, index) => (
                    <Grid item md={3} sx={(theme) => ({
                        borderRadius: theme.shape.borderRadius,
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                                : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                        outline: '1px solid',
                        gap: 2,
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
                                : 'white',
                    })} key={index}>
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                boxShadow: 1,
                                bgcolor: 'background.paper',
                                height: '100%',
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                                <Typography variant="h5" component="h2" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                                    {token.name}
                                </Typography>
                                <Typography variant="h6" component="h3" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                                    {token.symbol}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                                <Typography variant="h6" component="h3" sx={{ color: '#66ff00', fontWeight: 'bold' }}>
                                    {token.price}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 15 }}>
                                <Typography variant="h6" component="h3" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                                    {token.changePercentage24}
                                </Typography>
                                <Typography variant="h6" component="h3" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                                    {token.changePercentage1m}
                                </Typography>
                                <Typography variant="h6" component="h3" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                                    {token.changePercentage6m}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 15 }}>
                                <Button sx={(theme) => ({
                                    boxShadow:
                                        theme.palette.mode === 'light'
                                            ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                                            : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                                    outline: '1px solid',
                                    backgroundColor:
                                        theme.palette.mode === 'light'
                                            ? 'hsla(220, 25%, 80%, 0.5)'
                                            : 'hsla(210, 100%, 80%, 0.1)',
                                    height: 30,
                                    color:
                                        theme.palette.mode === 'light'
                                            ? 'black'
                                            : 'white',
                                    mt: theme.spacing(1),
                                })} variant="contained" color="primary">
                                Trade
                            </Button>
                            <Button sx={(theme) => ({
                                    boxShadow:
                                        theme.palette.mode === 'light'
                                            ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
                                            : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
                                    outline: '1px solid',
                                    backgroundColor:
                                        theme.palette.mode === 'light'
                                            ? 'hsla(100, 25%, 80%, 0.5)'
                                            : 'hsla(276, 100%, 50%, 0.1)',
                                    height: 30,
                                    color:
                                        theme.palette.mode === 'light'
                                            ? 'black'
                                            : 'white',
                                    mt: theme.spacing(1),
                                })} variant="contained" color="secondary">
                                Check
                            </Button>
                        </div>
                    </Stack>
                    </Grid>
                ))}
            <Grid item md={12}>
                <PromoBanner />
            </Grid>
        </Grid>

        </Box >
    )
}