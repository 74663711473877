import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { Avatar, Chip, IconButton } from '@mui/material';
import { EditNotificationsOutlined } from '@mui/icons-material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export function Deposits() {
  return (
    <React.Fragment>
      <Title>Recent Deposits</Title>
      <Typography component="p" variant="h4">
        $3,024.00
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div>
    </React.Fragment>
  );
}

export function NetWorth() {
  return (
    <React.Fragment>
      <Title>Recent Deposits</Title>
      <Typography component="p" variant="h4">
        $3,024.00
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div>
    </React.Fragment>
  );
}

export function TotalContracts() {
  return (
    <React.Fragment>
      <Title>Recent Deposits</Title>
      <Typography component="p" variant="h4">
        $3,024.00
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div>
    </React.Fragment>
  );
}

export function UserData() {
  return (
    <React.Fragment>
      <div style={{width: '200px', maxHeight: 10}}>
      <div style={{position: 'relative', top: -10, left: '100%'}}>
        <IconButton color="primary" aria-label="Edit Avatar">
          <EditNotificationsOutlined />
        </IconButton>
      </div>
      </div>
      <Title>Account</Title>
      <Typography component="p" variant="h4">
        $3,024.00
      </Typography>
      <div style={{display: 'flex', flexDirection: 'row'}}>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        0xab38...2837
      </Typography>
      </div>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div>
      
      <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, gap: 10, justifyContent: 'center'}}>
        <Chip label="LVL1 - Beginner" color="primary" />
        <Chip label="LVL2 - Intermediate" color="secondary" />
      </div>
    </React.Fragment>
  );
}
