import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Card as MuiCard } from '@mui/material';
import { styled } from '@mui/material/styles';
import LOGO from '../../static/images/cropped.png';
import { WalletOutlined } from '@mui/icons-material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { EtherContextRepository } from '../../helper/EtherContextRepository';
import { EtherContext } from '../../helper/EtherContext';
import AddressFactory from '../../common/AddressFactory';
import DatabaseHelper from '../../classes/DatabaseHelper';
import { IUser } from '../../entities/IUser';
import { SPVerifier } from '../_verifierDB/SPVerifier';

const verifyIfUserExists = async (email: string) => {
    const db = new DatabaseHelper(AddressFactory.getDBEndpoint(true));
    const user = await db.getUsers() as IUser;
    if (user?.email === email) {
        return true;
    } else {
        return false
    }
}

interface P_SignUp {
    username: string;
    email: string;
    password: string;
    isWallet: boolean;
    address?: string;
}

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    gap: theme.spacing(4),
    width: '100%',
    marginTop: 180,
    padding: theme.spacing(2),
    minHeight: 780,
    boxShadow:
        theme.palette.mode === 'light'
            ? 'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px, hsla(220, 30%, 5%, 0.05) 0px 0px 0px 1px'
            : 'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px, hsla(220, 30%, 5%, 0.05) 0px 0px 0px 1px',
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
        width: '450px',
    },
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
    height: 'auto',
    padingBottom: theme.spacing(12),
    backgroundImage:
        theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))'
            : 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.3), hsl(220, 30%, 5%))',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
        paddingBottom: 0,
        height: '120dvh',
    },
}));

async function checkIsExist(name: string) {
    const db = new DatabaseHelper(AddressFactory.getDBEndpoint(true));
    const user = await db.getUsers() as IUser;
    let isValid = true;
    if (user?.username === name) {
        isValid = false
    }
    return isValid;
}

export default function SignUp() {
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [nameError, setNameError] = React.useState(false);
    const [nameErrorMessage, setNameErrorMessage] = React.useState('');
    const [signUp, setSignUp] = React.useState<P_SignUp | null>(null);
    const [open, setOpen] = React.useState(false);
    const { context, saveContext } = React.useContext(EtherContext) as EtherContextRepository;

    const validateInputs = async () => {
        const email = document.getElementById('email') as HTMLInputElement;
        const password = document.getElementById('password') as HTMLInputElement;
        const name = document.getElementById('name') as HTMLInputElement;
        const isUserExist = await verifyIfUserExists(email.value);

        let isValid = true;

        if (isUserExist === true) {
            setEmailError(true);
            setEmailErrorMessage('User already exists.');
            isValid = false;
        }

        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value || password.value.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Password must be at least 6 characters long.');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        if (!name.value || name.value.length < 1) {
            setNameError(true);
            setNameErrorMessage('Name is required.');
            isValid = false;
        } else {
            setNameError(false);
            setNameErrorMessage('');
            checkIsExist(name.value).then((res) => {
                if (res === false) {
                    setNameError(true);
                    setNameErrorMessage('Name is already taken.');
                    isValid = false;
                } else {
                    setNameError(false);
                    setNameErrorMessage('');
                }
            });
        }

        return isValid;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            name: data.get('name'),
            lastName: data.get('lastName'),
            email: data.get('email'),
            password: data.get('password'),
        });
        setSignUp({
            username: '', // Add the username property here
            email: data.get('email') as string,
            password: data.get('password') as string,
            isWallet: false
        })
        handleOpen();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (

            <SignUpContainer direction="column" justifyContent="space-between">
                {context && (
                <SPVerifier
                    open={open}
                    handleClose={handleClose}
                    context={context}
                    signUp={signUp}
                    saveContext={saveContext}
                />
            )}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        position: { xs: 'static', sm: 'fixed' },
                        width: '100%',
                        p: { xs: 2, sm: 4 },
                    }}
                >
                    <Button
                        startIcon={<ArrowBackRoundedIcon />}
                        component="a"
                        href="/"
                    >
                        Back
                    </Button>
                </Stack>
                <Stack
                    justifyContent="center"
                    sx={{ height: { xs: '100%', sm: '100dvh' }, p: 2 }}
                >
                    <Card>
                        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                            <img alt="" src={LOGO} style={{ height: 'auto', width: 50 }} />
                        </div>
                        <Typography
                            component="h1"
                            variant="h4"
                            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                        >
                            Sign up
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                        >
                             <FormControl>
                                <FormLabel htmlFor="email">Username</FormLabel>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    placeholder="Smith177"
                                    name="name"
                                    autoComplete="name"
                                    variant="outlined"
                                    error={nameError}
                                    helperText={nameErrorMessage}
                                    color={nameError ? 'error' : 'primary'}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    placeholder="your@email.com"
                                    name="email"
                                    autoComplete="email"
                                    variant="outlined"
                                    error={emailError}
                                    helperText={emailErrorMessage}
                                    color={emailError ? 'error' : 'primary'}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    placeholder="••••••"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    variant="outlined"
                                    error={passwordError}
                                    helperText={passwordErrorMessage}
                                    color={passwordError ? 'error' : 'primary'}
                                />
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive updates via email."
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={validateInputs}
                            >
                                Sign up
                            </Button>
                            <Link
                                href="/material-ui/getting-started/templates/sign-in/"
                                variant="body2"
                                sx={{ alignSelf: 'center' }}
                            >
                                Already have an account? Sign in
                            </Link>
                        </Box>
                        <Divider>
                            <Typography color="text.secondary">or</Typography>
                        </Divider>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                onClick={() => alert('Sign in with Google')}
                                startIcon={<WalletOutlined />}
                            >
                                SIGN UP WITH WALLET
                            </Button>
                        </Box>
                    </Card>
                </Stack>
            </SignUpContainer>
    );
}