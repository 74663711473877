import * as React from 'react';
import { Avatar, PaletteMode } from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import ToggleColorMode from './ToggleColorMode';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import LOGO from '../static/images/cropped.png';
import { IUser } from '../entities/IUser';
import { EtherContext } from '../helper/EtherContext';
import { EtherContextRepository } from '../helper/EtherContextRepository';
import UserModal from './UserModals/UserModalNav';

const logoStyle = {
  width: '50px',
  height: 'auto',
  cursor: 'pointer',
};

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
  user: Partial<IUser> | null;
}

function AppAppBar({ mode, toggleColorMode, user }: AppAppBarProps) {
  const [open, setOpen] = React.useState(false);
  const [openUserModal, setOpenUserModal] = React.useState(false);

  const toggleUserModal = () => {
    setOpenUserModal(!openUserModal);
  }
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const { context, saveContext } = React.useContext(EtherContext) as EtherContextRepository

  return (
    <div>
      {user !== null && user.email && (
        <UserModal open={openUserModal} onClose={() => setOpenUserModal(false)} email={user.email} wallet={context.addressSigner ?? ''} username={user.username} />
      )}
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
            <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
              theme.palette.mode === 'light'
                ? 'rgba(75, 0, 130, 0.4)' // Dark purple for light mode
                : 'rgba(48, 25, 52, 0.4)', // Darker purple for dark mode
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 1px rgba(75, 0, 130, 0.1), 1px 1.5px 2px -1px rgba(75, 0, 130, 0.15), 4px 4px 12px -2.5px rgba(75, 0, 130, 0.15)`
                : '0 0 1px rgba(48, 25, 52, 0.7), 1px 1.5px 2px -1px rgba(48, 25, 52, 0.65), 4px 4px 12px -2.5px rgba(48, 25, 52, 0.65)',
            })}
            >
            <Box
              sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: '-18px',
              px: 0,
              }}
            >
              <img
              src={
                LOGO
              }
              style={logoStyle}
              alt="logo of ethercode"
              />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <MenuItem
                href='/ '
                sx={{ py: '6px', px: '12px' }}
                component="a"
              >
                <Typography variant="body2" color="text.primary">
                Home
                </Typography>
              </MenuItem>
              <MenuItem
                href='/dashboard'
                sx={{ py: '6px', px: '12px' }}
                component="a"
              >
                <Typography variant="body2" color="text.primary">
                Dashboard
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => scrollToSection('highlights')}
                sx={{ py: '6px', px: '12px' }}
              >
                <Typography variant="body2" color="text.primary">
                Tools
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => scrollToSection('highlights')}
                sx={{ py: '6px', px: '12px' }}
              >
                <Typography variant="body2" color="text.primary">
                Analytics
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => scrollToSection('pricing')}
                sx={{ py: '6px', px: '12px' }}
              >
                <Typography variant="body2" color="text.primary">
                News
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => scrollToSection('faq')}
                sx={{ py: '6px', px: '12px' }}
              >
                <Typography variant="body2" color="text.primary">
                FAQ
                </Typography>
              </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              {/** user section **/}

              {user !== null && (
              <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                <Button
                color="primary"
                variant="outlined"
                size="large"
                sx={{ gap: 1 }}
                onClick={toggleUserModal}
                >
                <Avatar sizes='small' sx={{ width: '20px', height: '20px' }} /> {user.username !== "" ? user.username : user.email?.split('@')[0]}
                </Button>

                {context.addressSigner !== undefined && (
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                >
                  {context.addressSigner?.slice(0, 6)}...{context.addressSigner?.slice(-4)}
                </Button>
                )}

                {context.addressSigner === undefined && (
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  disabled
                >
                  CONNECT WALLET
                </Button>
                )}
              </div>
              )}
              {user === null && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                color="primary"
                variant="text"
                size="small"
                disabled
                component="a"
                href="/signIn/"
                >
                Sign in
                </Button>
                <Button
                color="primary"
                variant="text"
                size="small"
                component="a"
                disabled
                href="/signUp"
                >
                Sign up
                </Button>
                <Button
                color="secondary"
                variant="contained"
                size="small"
                component="a"
                href="/signUp"
                disabled
                >
                CONNECT WALLET
                </Button>
              </div>
              )}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
              >
              <MenuTwoToneIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                minWidth: '60dvw',
                p: 2,
                backgroundColor: 'background.paper',
                flexGrow: 1,
                }}
              >
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  flexGrow: 1,
                }}
                >
                <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                </Box>
                <MenuItem component="a" href='/'>
                Home
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('testimonials')}>
                Dashboard
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('highlights')}>
                Tools
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('pricing')}>
                Analytics
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('pricing')}>
                News
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('pricing')}>
                FAQ
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem>
                <Divider />
                <MenuItem>
                <Button
                  color="primary"
                  variant="contained"
                  component="a"
                  href="/signUp"
                  disabled
                  sx={{ width: '100%' }}
                >
                  Sign up
                </Button>
                </MenuItem>
                <MenuItem>
                <Button
                  color="primary"
                  variant="outlined"
                  component="a"
                  href="/signIn"
                  disabled
                  sx={{ width: '100%' }}
                >
                  Sign in
                </Button>
                </MenuItem>
              </Box>
              </Drawer>
            </Box>
            </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;